import React from 'react'
import DesigningAnimation from './designingAnimation'

import styles from './home-header.module.css'

const HomeHeader = () => {
  return <div className={styles.root}>
      <div className={styles.text}>We build high quality apps for mobile and web</div>
      <div className={styles.animation}><DesigningAnimation /></div>
  </div>
}

export default HomeHeader